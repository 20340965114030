import React from 'react'
import Layout from '../components/Layout'
import hdiw1 from '../../static/img/hdiw/EDS_HDIW_1.gif'
import hdiw2 from '../../static/img/hdiw/EDS_HDIW_2.gif'
import hdiw3 from '../../static/img/hdiw/EDS_HDIW_3.gif'
import hdiw4 from '../../static/img/hdiw/EDS_HDIW_4.gif'
import hdiw5 from '../../static/img/hdiw/EDS_HDIW_5.gif'
import hdiw6 from '../../static/img/hdiw/EDS_HDIW_6.gif'
import hdiw7 from '../../static/img/hdiw/EDS_HDIW_7.gif'
import hdiw8 from '../../static/img/hdiw/EDS_HDIW_8.gif'
import hdiw9 from '../../static/img/hdiw/EDS_HDIW_9.gif'
import hdiw10 from '../../static/img/hdiw/EDS_HDIW_10.jpg'
const App = () => {
  return(
    <Layout wrapperClass='hdiw-page'>
      <div className='row'>
        <div className='col-lg-12 hdiw-intro'>
          <p>GETTING STARTED with the Elevator Design Studio (EDS) is easy. In
          10 simple steps, this tutorial will show you how to select your
          elevator interior configuration, apply materials and finishes,
          view your progress with realistic renderings, and manage your project
          from a single location.
          </p>
        </div>
      </div>
      <div className='row hdiw-page-item'>
        <div className='col-lg-7'>
          <img src={hdiw1} alt="Register to get started" />
        </div>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">1</span> REGISTER TO GET STARTED
          </h3>
          <p>Register a user account to unlock the Elevator Design Studio's
          powerful tools.</p>
          <p>After logging in, you'll be redirected to my.EDS dashboard
          comprised of your Elevator Design Studio projects, account
          information, and navigation menus.</p>
          <p>Getting started is easy: click the plus button and name your
          project. Keep in mind; you can create multiple designs under the same
          project name.</p>
          <p>In the event you already have an EDS user account, you will be
          prompted to import your legacy projects the first time you log in.
          The import process is fast and easy and can be completed at any time.
          </p>
        </div>
      </div>
      <div className='row hdiw-page-item reverse-col-order'>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">2</span> CHOOSE YOUR CONFIGURATION
          </h3>
          <p>The EDS workspace is comprised of the menu progress bar,
          configuration editor, and rendering.</p>
          <p>To start building your elevator interior, select one of our ten
          configurations. Each configuration can be selected with a single or
          double-ended opening style.</p>
          <p>When you're ready for the next step, click the green Next button.
          </p>
          <p>You can switch between elevator interior configurations or opening
          style mid-design. However, with the exception of switching from a
          single-ended to a double-ended door opening style, the material
          selections you've made will not migrate to your new configuration.
          You'll need to reselect your materials.
          </p>
        </div>
        <div className='col-lg-7'>
          <img src={hdiw2} alt="Choose your configuration" />
        </div>
      </div>
      <div className='row hdiw-page-item'>
        <div className='col-lg-7'>
          <img src={hdiw3} alt="Make your panel selection" />
        </div>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">3</span> MAKE YOUR PANEL SELECTION
          </h3>
          <p>Each elevator interior configuration comes with a unique wall
          panel layout, segmented into groups (letters) and sub-panels
          (numbers). You can select and deselect panels by clicking the group
          and/or sub-panel(s) in the editor on the left or the rendering on the
          right.
          </p>
        </div>
      </div>
      <div className='row hdiw-page-item reverse-col-order'>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">4</span> APPLY MATERIALS
          </h3>
          <p>Within the editor, you can search our standard inset materials,
          apply finishes and patterns, view F+S favorites, or save your library
          of favorites for future use. If available for your panel selection,
          you may find the option to apply a "Custom" swatch to indicate a
          third party material.</p>
          <p>Visualize your material selections by clicking APPLY.</p>
        </div>
        <div className='col-lg-7'>
          <img src={hdiw4} alt="Apply materials" />
        </div>
      </div>
      <div className='row hdiw-page-item'>
        <div className='col-lg-7'>
          <img src={hdiw5} alt="Make your panel selection" />
        </div>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">5</span> SELECT A HANDRAIL
          </h3>
          <p>Handrails and crash rail are optional and may be specified for
          elevator back walls and/or sidewalls. Choose from six standard
          handrail series offering a wide range of design and material
          possibilities. Crash rail comes in one style and material option. To
          apply, click on the handrail or/and crash rail configuration of your
          choice.
          </p>
        </div>
      </div>
      <div className='row hdiw-page-item reverse-col-order'>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">6</span> SELECT A CEILING
          </h3>
          <p>Ceilings may be specified with one of several lighting
          configurations, light temperature, materials, and finishes. To apply,
          click on the ceiling configuration of your choice.</p>
          <p>At this stage, you're able to pick a flooring option shown for
          visual reference only.</p>
        </div>
        <div className='col-lg-7'>
          <img src={hdiw6} alt="Select a ceiling" />
        </div>
      </div>
      <div className='row hdiw-page-item'>
        <div className='col-lg-7'>
          <img src={hdiw7} alt="Viewing Options" />
        </div>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">7</span> VIEWING OPTIONS
          </h3>
          <p>Now that you are nearly done designing your elevator interior, why
          not review it from different  angles? Just click on any view options
          icon to the right of your rendering to open it in a spotlight window.
          You can download each view as a single image.
          </p>
        </div>
      </div>
      <div className='row hdiw-page-item reverse-col-order'>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">8</span> REVIEW YOUR DESIGN
          </h3>
          <p>Your elevator interior is complete! Feel free to provide
          additional details in the blank fields found in the editor.</p>
          <p>Dimensions are needed to submit an advanced download request.
          Advanced download documents include an unmarked EDS overview PDF with
          weight estimation and the elevator interior design CSI specs.</p>
        </div>
        <div className='col-lg-7'>
          <img src={hdiw8} alt="Review your design" />
        </div>
      </div>
      <div className='row hdiw-page-item'>
        <div className='col-lg-7'>
          <img src={hdiw9} alt="Download" />
        </div>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">9</span> DOWNLOAD
          </h3>
          <p>EDS designs can be downloaded as a comprehensive PDF or single
          image for easy reference and sharing.
          </p>
        </div>
      </div>
      <div className='row hdiw-page-item reverse-col-order'>
        <div className='col-lg-5'>
          <h3><span class="badge secondary">10</span> GO TO PROJECT
          </h3>
          <p>Create multiple elevator interior designs and link them to one
          project to easily manage your information from a single location.</p>
        </div>
        <div className='col-lg-7'>
          <img src={hdiw10} alt="Go to project" />
        </div>
      </div>
    </Layout>
  )
}

export default App
